<template>
  <db-record-viewer :customer-calls="customerCalls" />
</template>

<script>
import DbRecordViewer from "../components/DbRecordViewer";

export default {
  name: "DBRecords",
  data: () => {
    return {
      customerCalls: [],
    };
  },
  components: {
    DbRecordViewer,
  },
  mounted() {
    // this.refreshInterval = setInterval(this.refreshCalls, 10000);
    this.refreshCalls();
  },
  beforeUnmount() {
    // if (this.refreshInterval) clearInterval(this.refreshInterval);
    return true
  },
  methods: {
    refreshCalls() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}dbrecords`)
        .then((response) => {
          this.customerCalls = response.body;
        })
        .catch(console.error);
    },
  },
};
</script>
