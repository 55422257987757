<template>
  <queue-calls-viewer :customer-calls="customerCalls" :accounts="accounts" />
</template>

<script>
import QueueCallsViewer from "../components/QueueCallsViewer";

export default {
  name: "QueueCalls",
  data: () => {
    return {
      customerCalls: [],
      accounts: [],
    };
  },
  components: {
    QueueCallsViewer,
  },
  mounted() {
    this.refreshInterval = setInterval(this.refreshCalls, 3000);
    this.refreshCalls();

    this.$http
      .get(`${process.env.VUE_APP_API_URL}accounts`)
      .then((response) => {
        this.accounts = response.body;
      })
      .catch(console.error);
  },
  beforeUnmount() {
    if (this.refreshInterval) clearInterval(this.refreshInterval);
    return true
  },
  methods: {
    refreshCalls() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}queuecalls`)
        .then((response) => {
          this.customerCalls = response.body;
        })
        .catch(console.error);
    },
  },
};
</script>
