<template>
    <v-layout row wrap>
        <v-flex xs12 sm6 md3 v-for="(stat, i) in stats" :key="i">
            <a :href="'/'+stat.url" class="w-full">
                <v-card class="m-4 cursor-pointer group">
                    <v-card-title>
                        {{ stat.title }}
                        <v-icon class="group-hover:ml-1">mdi-arrow-right</v-icon>
                    </v-card-title>
                    <v-card-text>{{ stat.value }}</v-card-text>
                </v-card>
            </a>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    data() {
        return {
            stats: [
                { title: "Queue Calls", value: "Loading..", url: "queuecalls" },
                { title: "QB Calls", value: "Loading..", url: "qbcalls" },
                // { title: "FS Events", value: "Loading..", url: "fsevents" },
                // { title: "DB Records", value: "Loading..", url: "dbrecords" },
            ],
            refreshInterval: null,
        }
    },

    mounted() {
        this.refreshAllStats()

        this.refreshInterval = setInterval(() => {
            this.refreshAllStats()
        }, 3000)
    },

    // beforeUnmount() {
    //     if (this.refreshInterval)
    //         clearInterval(this.refreshInterval)

    //     return true
    // },

    methods: {
        refreshAllStats() {
            this.stats.forEach((stat, index) => {
                this.refreshStat(index, stat.url)
            })
        },
        refreshStat(index, url) {
            this.$http
                .get(`${process.env.VUE_APP_API_URL}${url}`)
                .then(response => {
                    this.stats[index].value = response.body?.length || 0
                })
                .catch(console.error)
        }
    }
}
</script>
