<template>
    <div class="px-4 sm:px-6 lg:px-8 mt-8">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h1 class="text-xl font-semibold text-gray-900">Queuing Calls</h1>
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button type="button"
                    class="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:w-auto">
                    Refresh
                </button>
            </div>
        </div>
        <div class="mt-8 flex flex-col">
            <div class="-mx-4">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="shadow-sm ring-1 ring-black ring-opacity-5">
                        <table class="min-w-full border-separate" style="border-spacing: 0">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                        From
                                    </th>
                                    <th scope="col"
                                        class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                        To
                                    </th>
                                    <th scope="col"
                                        class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                        Start
                                    </th>
                                    <th scope="col"
                                        class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                        Domain
                                    </th>
                                    <th scope="col"
                                        class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                                        Account
                                    </th>
                                    <th scope="col"
                                        class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                        <span class="sr-only">Refresh</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <tr v-for="(call, callIdx) in calls" :key="call.id">
                                    <td :class="[
                                        callIdx !== calls.length - 1
                                            ? 'border-b border-gray-200'
                                            : '',
                                        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                    ]">
                                        {{ call.initial_cid_num }}
                                    </td>
                                    <td :class="[
                                        callIdx !== calls.length - 1
                                            ? 'border-b border-gray-200'
                                            : '',
                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell',
                                    ]">
                                        {{ call.initial_dest }}
                                    </td>
                                    <td :class="[
                                        callIdx !== calls.length - 1
                                            ? 'border-b border-gray-200'
                                            : '',
                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                                    ]">
                                        {{ call.created }}
                                    </td>
                                    <td :class="[
                                        callIdx !== calls.length - 1
                                            ? 'border-b border-gray-200'
                                            : '',
                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell',
                                    ]">
                                        {{ call.context }}
                                    </td>
                                    <td :class="[
                                        callIdx !== calls.length - 1
                                            ? 'border-b border-gray-200'
                                            : '',
                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell',
                                    ]">
                                        {{ lookupAccountName(call.dest) || 'Unknown' }}
                                    </td>
                                    <td :class="[
                                        callIdx !== calls.length - 1
                                            ? 'border-b border-gray-200'
                                            : '',
                                        'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8',
                                    ]">
                                        <a @click="viewCall(call.uuid, lookupAccount(call.dest))"
                                            class="text-sky-600 hover:text-sky-900">View<span class="sr-only">View {{
                                                call.from }}</span></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <v-dialog v-model="dialog" class="w-[11/12] md:w-[640px]">
                <v-card class="p-4">
                    <h3>Acccount</h3>
                    <json-viewer :value="selectedAccount" :expand-depth="0" copyable boxed sort></json-viewer>
                    <h3>Call</h3>
                    <json-viewer :value="selectedCall" :expand-depth="0" copyable boxed sort></json-viewer>
                    <v-card-actions>
                        <v-btn color="primary" block @click="closeDialog()">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import "@/main.css";
export default {
    data() {
        return {
            calls: [],
            accounts: [],
            refreshInterval: null,
            dialog: false,
            selectedAccount: null,
            selectedCall: null
        }
    },

    mounted() {
        this.refreshQueueCalls()

        this.refreshAccounts()

        this.refreshInterval = setInterval(() => {
            this.refreshQueueCalls()
        }, 3000)
    },

    methods: {
        refreshAccounts() {
            this.$http
                .get(`${process.env.VUE_APP_API_URL}accounts`)
                .then(response => {
                    this.accounts = response.body
                })
                .catch(console.error)
        },
        refreshQueueCalls() {
            this.$http
                .get(`${process.env.VUE_APP_API_URL}queuecalls`)
                .then(response => {
                    this.calls = response.body
                })
                .catch(console.error)
        },
        lookupAccount(dest) {
            let accountId = dest?.replace('dd_queue_', '')

            if (!accountId)
                return dest

            let account = this.accounts.find(account => account.id === accountId)

            return account || {}
        },
        lookupAccountName(dest) {
            let account = this.lookupAccount(dest)

            return account?.config_json?.name
        },
        closeDialog() {
            this.dialog = false
            this.selectedCall = null
            this.selectedAccount = null
        },
        viewCall(uuid, account) {
            this.selectedAccount = account
            this.$http
                .get(`${process.env.VUE_APP_API_URL}queuecall/${uuid}`)
                .then(response => {
                    this.selectedCall = response.body
                })
            this.dialog = true
        }
    }
}
</script>
