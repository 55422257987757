<template>
  <v-container>
    <v-row>
      <v-col cols="4" v-for="queueCall in customerCalls">
        <v-card class="pa-2" outlined tile>
          <h3 class="text-center">{{ queueCall.uuid }}</h3>
          <p class="text-center">
            {{ formatEpochDate(queueCall.created_epoch) }}
          </p>
          <p class="text-center">{{ lookupAccount(queueCall.dest) }}</p>
          <json-viewer
            :value="queueCall"
            :expand-depth="0"
            copyable
            boxed
            sort
          ></json-viewer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import JsonViewer from "vue-json-viewer";

Vue.use(JsonViewer);

export default {
  props: ["customerCalls", "accounts"],

  methods: {
    formatEpochDate(epoch) {
      return new Date(parseInt(epoch) * 1000).toLocaleString();
    },
    lookupAccount(dest) {
      let account = this.accounts?.find(
        (account) => account.id === dest?.replace("dd_queue_", "")
      );

      if (account)
        return `${account.config_json.name} (${account.domain_name})`;
      else return dest?.replace("dd_queue_", "");
    },
  },
};
</script>
